import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  link: {
    color: '#555',
    margin: '0 10px',
  },
  footer: {
    borderTop: '1px solid #eee',
    display: 'flex',
    justifyContent: 'center',
    fontSize: 12,
    padding: 20,
  },
}));

export const Footer = () => {

  const dateTime = new Date();
  const showTime = dateTime.getHours() + ':' + dateTime.getMinutes() + ":" + dateTime.getSeconds();
  const classes = useStyles();
  const current = new Date();
  const date = `${current.getDate()}/${current.getMonth()+1}/${current.getFullYear()}`;

  return (
    <div className={classes.footer}>
     Rendered on - {date} - {showTime}
    </div>
  );
};
